// @ts-check
import React, { useEffect, useState } from "react";
import { Form, NavLink, redirect, useActionData, useFetcher, useLoaderData, useParams } from "react-router";
import DeclineInvitation from "./DeclineInvitation.js";
import { TermsAndPrivacy } from "../UserFormComponents/TermsAndPrivacy.js";
import { PersonalDetailsForm } from "../UserFormComponents/PersonalDetailsForm.js";
import { NewsletterOptIn } from "../NewsletterOptIn/NewsletterOptIn.js";
import { LicenseTypeToString } from "./MyPool/MyPool.js";

export async function acceptInvitationAction({ request, params }){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();
    const newPassword1 = formData.get("user_register[plainPassword][first]");
    const newPassword2 = formData.get("user_register[plainPassword][second]");
    if (newPassword1.length < 8){
        return ({success: false, error: "Ihr Passwort muss mindestens 8 Zeichen lang sein.", target: "password"});
    }
    if (newPassword1 !== newPassword2){
        return ({success: false, error: "Die Passwörter stimmen nicht überein.", target: "password"});
    }
    const res = await fetch(__API_URL__ + "/webapi/accept-invitation/" + params.invitation + "/" + params.invitationCode, {method: "POST", body: JSON.stringify({
        newPassword1: formData.get("user_register[plainPassword][first]"),
        newPassword2: formData.get("user_register[plainPassword][second]"),
        accept_privacy: formData.get("user_register[privacy_policy_accepted]") === "1",
        accept_terms: formData.get("user_register[terms_accepted]") === "1",
        newsletter:  formData.get("user_register[email_newsletter]") === "1",
        first_name: formData.get("user_register[firstName]"),
        last_name: formData.get("user_register[lastName]"),
        greeting: formData.get("user_register[greeting]")
    })});
    const accept_data = await res.json();
    accept_data.action = "invitation-accepted";

    if (accept_data.success === true){
        return redirect("/account");
    }
    else{
        return accept_data;
    }

}


export async function acceptExistingUserInvitation({params, request}){
    var url = __API_URL__ + "/webapi/accept-invitation-existing-user/" + params.invitation;
    const res = await fetch(url, {
        method: "POST",
        signal: request.signal});
    
    const accept_data = await res.json();

    if (accept_data.success === true){
        return redirect("/account");
    }
    else{
        return json(accept_data);
    }
}

export async function acceptInvitationLoader({params, request}){
    var url = __API_URL__ + "/webapi/check-invitation/" + params.invitation + "/" + params.invitationCode;
    if (params.invitationCode === undefined){
        url = __API_URL__ + "/webapi/check-invitation/" + params.invitation
    }
    const res = fetch(url, {
        method: "POST",
        signal: request.signal});
    return res;
}

function AcceptingForm({invitation, invitationCode, email, message_block}){
    return <Form method="POST">
    <>
    <input type="hidden" value={invitation} name="invitation" />
    <input type="hidden" value={invitationCode} name="invitationCode" />
    <div>
<span className="fake-label required">Benutzername</span>
<p>{email}</p>
</div>
<PersonalDetailsForm showPhoneNumber={false} />
<div>
    <p>Wählen Sie jetzt ein neues Passwort für Ihr telani-Konto:</p>
<label htmlFor="user_register_plainPassword_first" className="required">Neues Passwort (min 8 Zeichen)
<input type="password" id="user_register_plainPassword_first" name="user_register[plainPassword][first]" required={true} autoComplete="new-password" className="form-control" />
</label>
</div>
<div>    
<label htmlFor="user_register_plainPassword_second" className="required">Passwort wiederholen
<input type="password" id="user_register_plainPassword_second" name="user_register[plainPassword][second]" required={true} autoComplete="new-password" className="form-control" />
</label>
</div>
    <h5>E-Mail Newsletter</h5>
    <NewsletterOptIn />
    <h5>AGB und Datenschutzerklärung</h5>
    <TermsAndPrivacy />

{message_block}
<input type="submit" value="Los gehts!" />
</>
</Form>
}


export function AcceptInvitation(){
    const accept_existing_fetcher = useFetcher();

    const {success: verification_result, email, existingUser, invitingUser, licenseType, needsLogin, wrongUser, hasLicense} = useLoaderData();

    const licenseName = LicenseTypeToString(licenseType);

    const actionData = useActionData();

    const [accepted, setAccepted] = useState(false);
    const [decliningInvitation, setDecliningInvitation] = useState(false);

    const params = useParams();
    let message = null;

    if (verification_result !== true){
        if (needsLogin === true){
            const url = "/login?next=" + encodeURIComponent(window.location.href);
            return <><p>Bitte melden Sie sich an um diese Einladung anzunehmen:</p><NavLink to={url} type="button" className="button primary">Anmelden</NavLink></>
        }
        if (wrongUser === true){
            return <><p>Diese Einladung ist für einen anderen Nutzer bestimmt.</p></>
        }
        if (hasLicense === true){
            return <><p>Sie können keine Einladung annehmen, da Sie bereits eine Lizenz haben.</p></>
        }
        return <p>Dieser Link ist leider nicht mehr gültig.</p>
    }

    if (actionData !== null && actionData !== undefined 
        && actionData.success === true && actionData.action === "invitation-declined"){
        return <p>Sie haben diese Einladung abgelehnt.</p>
    }

    if (accept_existing_fetcher.data){
        console.log(accept_existing_fetcher.data);
        return <h1>Einladung erfolgreich angenommen.</h1>
    }

    const clickAccept = () => {
        if (existingUser){
            console.log("existing user accepted license");
            accept_existing_fetcher.submit({}, {method: "POST", action: "/webapi/accept-invitation-existing-user/" + params.invitation});
        }
        else{
            setAccepted(true);
        }
    }

    if (actionData !== undefined){
        if (actionData.success){
            // this should not really happen, because in this case the action should return a redirect.
        }
        else{
            message = actionData.error !== undefined ? actionData.error : "Es ist ein Fehler aufgetreten.";
        }
    }
    let message_block = message !== null ? <div className="alert alert-danger">{message}</div> : null;

    return <>
    <p>Sie wurden eingeladen telani {licenseName} zu nutzen.</p>
    <p>Für die Nutzung von telani {licenseName} entstehen Ihnen durch den Hersteller von telani,
         der gb&t Gebäudebestand und Technik GmbH, <strong>keine</strong> Kosten. </p>
    {accepted ?
        <>
            <a href="#" onClick={() => setAccepted(false)}>Zurück</a>
            <AcceptingForm invitation={params.invitation}
                invitationCode={params.invitationCode}
                email={email}
                message_block={message_block} />
        </>
        :
        <>
        {!decliningInvitation &&
            <button className="primary invitationAccept" onClick={clickAccept}>Akzeptieren</button>}
            <DeclineInvitation key="decline" onDecliningInvitation={val => setDecliningInvitation(val)} email={invitingUser} invitation={params.invitation} invitationCode={params.invitationCode} />
        </>
    }
    </>

}