// @ts-check
import React from "react"
import FormGroup from "../FormGroup.js";


export function PersonalDetailsForm({currentlyLoading = false, showPhoneNumber = true}) 
{
    return (<>
        <FormGroup required={true} label_for="greeting" label="Anrede"> 
        <select 
            defaultValue=""
            disabled={currentlyLoading}
            name="user_register[greeting]" className="" id="greeting">
            <option value=""></option>
            <option value="Frau">Frau</option>
            <option value="Frau Dr.">Frau Dr.</option>
            <option value="Herr">Herr</option>
            <option value="Herr Dr.">Herr Dr.</option>
        </select>
    </FormGroup>
    <FormGroup label_for="firstName" label="Vorname"> 
        <input type="text"
            required={true}
            disabled={currentlyLoading}
            id="firstName"
            name="user_register[firstName]" className="form-control" />
    </FormGroup>
    <FormGroup label_for="lastName" label="Nachname">
        <input type="text"
            required={true}
            disabled={currentlyLoading}
            name="user_register[lastName]"
            id="lastName"
            className="form-control" />
    </FormGroup>
    <FormGroup label_for="companyName" label="Firmenname">
        <input type="text"
            required={true}
            disabled={currentlyLoading}
            name="user_register[companyName]"
            id="companyName"
            className="form-control" />
    </FormGroup>
    {showPhoneNumber && <div>
        <label htmlFor="user_register_phone_number" className="required">Telefonnummer
            <input type="text" id="user_register_phone_number"
             disabled={currentlyLoading}
             name="user_register[phone_number]" required={true} className="form-control" />
        </label>
    </div>}
    </>);
}