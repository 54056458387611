// @ts-check
import React, { useRef, useState } from "react";
import { Form, json, Navigate, redirect, useActionData, useLoaderData, useNavigate, useParams } from "react-router";
import FormGroup from "../FormGroup.js";
import { useTitle } from "../AccountRoot.js";
import { LicenseTypeToString } from "./MyPool/MyPool.js";

export async function myPoolAddAction({ request, params }){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();
    console.log("SUBMITTING INVITATION");
    const res = await fetch(__API_URL__ + "/webapi/create-invite/" + formData.get("licenseId"),
    {
        signal: request.signal,
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            email: formData.get("email"),
            managePool: formData.get("managePool") === "1"
        })
    });
    if (res.status === 401){
        return redirect(login_url);
    }
    return res.json();
}


export async function myPoolAddLoader({params, request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    let url = "/webapi/userinfo";
    if (params.userId !== undefined){
        url = url + "/" + params.userId;
    }
    let res = await fetch(__API_URL__ + url,{
        signal: request.signal
    });
    if (res.status === 401){
        return redirect(login_url);
    }
    const user = await res.json();
    if (user.licensePool !== null && user.licensePool !== ""){
        let res = await fetch(__API_URL__ + "/webapi/pool/" + user.licensePool,
            {
            signal: request.signal
        });
        if (res.status === 401){
            return redirect(login_url);
        }
        return res;
    }
    else{
        return null;
    }
    
}


export function MyPoolAdd(){

    const emailRef = useRef(null);

    const navigate = useNavigate();
    let message = null

    const { userId } = useParams()

    const pool = useLoaderData();

    const action_result = useActionData();
    if (action_result !== undefined && action_result !== null){
        if (action_result.success){
            if (emailRef.current !== null){
                emailRef.current.value = '';
            }
            message = {isError: false, message: "Einladung wurde erfolgreich versendet."};
        }
        else{
            message = {isError: true, message: action_result.message !== undefined ? action_result.message : "Es ist ein Fehler aufgetreten"};
        }
    }

    let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;
    
    useTitle("Lizenz an Nutzer vergeben");

    const unique_licenses = pool.available_licenses.filter((value, index, self) => {
        return self.findIndex(v => v.licenseType === value.licenseType) === index;
      })

    if (unique_licenses.length === 0){

        const poolIdComponent = userId !== undefined && userId !== null ? "/" + userId : "";

        return <Navigate to={"/account/my-pool" + poolIdComponent + "?invite_success=true"} />
    }

    return <>
    <a href="#" onClick={() => navigate(-1)}>Zurück</a>
    <Form method="POST">
        <input type="hidden" name="poolid" value={pool.id} />
        <FormGroup label_for="email" label="E-Mail">
            <input required name="email" type="email" ref={emailRef} />
        </FormGroup>
        <FormGroup label_for="email" label="Lizenz (bitte auswählen)">
            <select required id="licenseType" name="licenseId" className="form-control">
                {unique_licenses.map(li => 
                    <option key={li.id} value={li.id}>{LicenseTypeToString(li.licenseType)}</option>
                )}
            </select>
        </FormGroup>
        {message_block}
        <input type="submit" value="Einladen" />
        <p>Wir senden einen Einladungslink an die von Ihnen angegebene E-Mail-Adresse. Nach Annahme der Einladung kann die betreffende Person telani Insight bzw. telani onSite nutzen.
        Sie haben jederzeit die Möglichkeit, diese Lizenz zurückzuziehen. Sollte diese Lizenz aktuell offline genutzt werden, kann dies jedoch bis zu 4 Tage in Anspruch nehmen.</p>
    </Form>
    </>
}